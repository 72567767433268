@import './fonts/fonts.css';

.App {
  text-align: center;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
}



