@import "../../styles/mixins";

.latest-item {
  max-width: 470px;
  background-color: #d8d8d8;
  border-radius: 12px;
  @include respond-to(small) {
    max-width: 95%;
    width: 100%;
  }
  &__container {
    display: grid;
    justify-items: center;
    padding: 1rem 0.4rem;
    background-color: #ececec;
    border-radius: 12px;
    gap: 1rem;
    align-items: center;
    grid-template-columns: 1fr 35px 1fr;
    @include respond-to(small) {
      box-sizing: border-box;
      flex-direction: column;
      // width: 300px;
    }
  }
  &__date {
    padding: 0.5rem;
    font-size: 12px;
  }
  &__img {
    @include respond-to(small) {
      rotate: 90deg;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
