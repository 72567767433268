@import "../../styles/mixins";

.main {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  align-self: center;
  width: 100%;
  @include respond-to(big) {
    max-width: 700px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    text-align: start;
    max-width: 320px;
    margin-bottom: 4rem;
    @include respond-to(small) {
      margin-bottom: 2rem;
    }
    
  }

  &__title {
    margin: 1rem 0;
    @include respond-to(large) {
      margin-left: 1rem;
    }
  }

}
