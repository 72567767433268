@import "../../styles/mixins";

.rules {
  padding: 20px;
  // background-color: #f9f9f9;
  color: #333;
  font-family: Arial, sans-serif;

  &__title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #222;
    margin-top: 0;
  }

  &__section {
    margin-bottom: 20px;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
  }

  &__subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #444;
    margin: 0 0 2rem;
    @include respond-to(small) {
      margin: 0 0 1rem;
    }
  }

  &__text {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
    margin-top: 10px;
  }

  &__list-item {
    margin-bottom: 10px;
    padding: 10px;
    border-left: 4px solid #007bff;
    // background-color: #f1f1f1;
  }
}
