@import "../../styles/mixins";

.latest {
    margin-bottom: 4rem;
    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        @include respond-to(big) {
            grid-template-columns: 1fr;
            justify-items: center;
          }
    }
}