@import "../../styles/mixins";

.container {
  background-color: #3a3a3a;
  padding: 64px 20px 20px;
  border-radius: 15px;
  width: fit-content;
  color: #fff;
  align-self: center;
  @include respond-to(big) {
    padding: 64px 40px 20px;
  }
  @include respond-to(small) {
    padding: 3rem 2rem 1rem;
    width: 100%;
    box-sizing: border-box;
  }
}

.item {
  position: relative;
}

.exchange-block {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 1rem;
  @include respond-to(big) {
    flex-direction: column;
    align-items: center;
  }
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: none;
  padding: 0.2rem 0.5rem;
  border-radius: 15px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  height: 90px;
  background-color: #5a5a5a;
  @include respond-to(small) {
    height: max-content;
  }
}

.input {
  background-color: #424242;
  border: none;
  // padding-left: .5rem;
  border-radius: 15px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  height: 80px;
  @include respond-to(small) {
    height: 60px;
    max-width: 160px;
  }

  &:focus-visible {
    outline: 1px solid black;
  }
}

.custom-select {
  background-color: #424242;
  color: #fff;
  padding: 10px 20px;
  border-radius: 15px; /* Делаем border-radius такой же как у инпута */
  width: 150px;
  height: 80px; /* Устанавливаем такую же высоту как у инпута */
  @include respond-to(small) {
    height: 60px;
    width: fit-content;
  
  }
  & .MuiSelect-icon {
    color: #fff; /* Стрелка select */
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none; /* Убираем стандартную обводку */
    border-radius: 15px;
  }

  &:hover {
    background-color: #383838;
  }
}

.MuiSelect-select {
  padding-right: 1rem !important; /* Применение нужного паддинга */
  width: fit-content;
}

.selected-item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  @include respond-to(small) {
    height: max-content;
    width: fit-content;
    font-size: 1rem;
  }
}

.currency-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  @include respond-to(small) {
    width: 25px;
    height: 25px;
  }
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  background-color: #3a3a3a;
  
  width: 100%;
  justify-content: space-between;
  @include respond-to(small) {
    height: max-content;
    font-size: 1rem;
    
  }
}

.MuiInputBase-root {
  border-radius: 15px !important;
}

.MuiPaper-root {
  background-color: #3a3a3a !important; /* Фон выпадающего списка */
  border-radius: 15px !important;
}

.MuiMenuItem-root {
  &:hover {
    background-color: #000000;
  }
}

.exchange-button {
  background-color: #ffb300;
  border: none;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 25px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
  @include respond-to(small) {
    width: 80%;
    margin: 1rem;
  }

  &:hover {
    background-color: #ffa000;
  }
}

.exchange-rate {
  font-size: 12px;
  margin-top: 1rem;
  color: #ccc;
}

.you-p {
  text-align: start;
  color: #e6e6e6;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 0.2rem;
  position: absolute;
  top: -25px;
  left: 10px;
}

.coin-button {
  background: #3a3a3a;
  border: none;
  cursor: pointer;
  margin: 1rem;
  @include respond-to(small) {
    rotate: 90deg;
  }

  &__img {
    @include respond-to(small) {
      width: 2rem;
      height: 2rem;
    }
  }
}


