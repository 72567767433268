@import "../../styles/mixins";

.contact {
  margin-bottom: 4rem;
  @include respond-to(small) {
    margin: 2rem 0;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__span {
    display: flex;
    height: 84px;
    width: 5px;
    background-color: #d9d9d9;
    margin: 0 1rem;
  }

  &__mail {
    color: black;
    text-decoration: none;
    width: 60px;
    height: 60px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
