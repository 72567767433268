@mixin respond-to($size) {
    @if $size == small {
      @media (max-width: 580px) {
        @content;
      }
    } @else if $size == medium {
      @media (max-width: 700px) {
        @content;
      }
    } @else if $size == large {
      @media (max-width: 856px) {
        @content;
      }
    } @else if $size == big {
      @media (max-width: 1024px) {
        @content;
      }
    }
  }

  @mixin flexCenter() {
    display: flex;
    justify-content: center;
    align-items: center;
  }