@import "../../styles/mixins";

.cooperate {
  margin-top: 1rem;
  margin-bottom: 4rem;

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    gap: 2rem;
    @include respond-to(big) {
      grid-template-columns: 1fr;
    }
  }
  &__title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  }
  &__img {
    max-width: 200px;
    min-height: 45px;
  }
  &__span {
    display: flex;
    height: 140px;
    width: 4px;
    background-color: #d9d9d9;
    margin: 0 1rem;
    @include respond-to(big) {
      width: 100%;
      height: 4px;
      margin: 1rem 0;
    }
  }
}
