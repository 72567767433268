@import "../../styles/mixins";

.qa {
  margin-top: 5rem;
  margin-bottom: 5rem;
  @include respond-to(big) {
    margin: auto;
  }
  @include respond-to(large) {
    margin: auto 1rem;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem !important;
  }
  &__answer {
    // background-color: #E6E6E6;
    color: #000000;
    // padding: 1rem;
    border-radius: 10px;
    // font-family: "MuseoModerno", Arial, Helvetica, sans-serif;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    white-space: pre-wrap;
    line-height: 1.5;
    text-align: left;
  }
}

.custom-accordion {
  background-color: #cfcfcf !important; /* Цвет фона */
  color: #000000 !important;
}

.custom-details {
  background-color: #e6e6e6 !important; /* Цвет фона */
  color: #000000 !important;
  border-top-left-radius: 10px !important; /* Закругление левого top угла */
  border-top-right-radius: 10px !important; /* Закругление right top угла */
  border-bottom-right-radius: 10px !important; /* Закругление правого нижнего угла */
  border-bottom-left-radius: 10px !important; /* Закругление левого нижнего угла */
  border-bottom-right-radius: 10px !important; /* Закругление правого нижнего угла */
  overflow: hidden !important; /* Чтобы содержимое не выходило за границы закругленного угла */
}
