.about {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  max-height: 100vh;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.about__title {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 0;
}

.about__text {
  font-size: 1rem;
  text-align: center;
  max-width: 800px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
