.coin-item {
  display: flex;
  align-items: center;
  &__text {
    font-size: 14px;
    color: #4d4d4d;
    // margin-left: 0.2rem;
    font-weight: 400;
    line-height: 17px;
  }
  &__image {
    max-width: 24px;
    max-height: 24px;
    border-radius: 50%;
    margin-right: .5rem;
  }
  &__title {
    font-size: 16px;
    color: #000000;
    line-height: 20px;
  }
  &__container {
    display: flex;
    align-items: center;
    gap: .2rem;
  }
}
